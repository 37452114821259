import { Box, Flex, Grid, GridItem, Image, Link, Text } from "@chakra-ui/react";
import React from "react";
import Footer from "../footer";
const NotFound = () => <Flex direction="column" sx={{
  minHeight: "100vh"
}} data-sentry-element="Flex" data-sentry-component="NotFound" data-sentry-source-file="NotFound.tsx">
    <Flex alignItems="center" flex={1} textAlign="center" data-sentry-element="Flex" data-sentry-source-file="NotFound.tsx">
      <Grid templateColumns="repeat(2, 1fr)" gap={4} data-sentry-element="Grid" data-sentry-source-file="NotFound.tsx">
        <GridItem display="inline-grid" colSpan={{
        base: 2,
        lg: 1
      }} order={{
        base: -1,
        lg: 1
      }} data-sentry-element="GridItem" data-sentry-source-file="NotFound.tsx">
          <Image src="/images/lostCat.svg" alt="" width="75%" data-sentry-element="Image" data-sentry-source-file="NotFound.tsx" />
        </GridItem>
        <GridItem display="inline-grid" colSpan={{
        base: 2,
        lg: 1
      }} data-sentry-element="GridItem" data-sentry-source-file="NotFound.tsx">
          <Text as="h1" fontSize={{
          base: "54px",
          md: "76px"
        }} mt={{
          base: 4,
          md: 0
        }} data-sentry-element="Text" data-sentry-source-file="NotFound.tsx">
            404
          </Text>
          <Text fontSize={{
          base: "16px",
          md: "18px"
        }} fontWeight={700} data-sentry-element="Text" data-sentry-source-file="NotFound.tsx">
            Vous êtes perdu ?
          </Text>
          <Text fontSize={{
          base: "14px",
          md: "18px"
        }} data-sentry-element="Text" data-sentry-source-file="NotFound.tsx">
            Il semble que la page que vous essayez de rejoindre n&apos;existe pas. En cas de problème pour retrouver la page, essayez de repartir de la page d&apos;accueil en
            cliquant sur le lien ci-dessous.
          </Text>
          <Box mt={8} mb={{
          base: 8,
          lg: 0
        }} data-sentry-element="Box" data-sentry-source-file="NotFound.tsx">
            <Link variant="postuler" href="https://labonnealternance.apprentissage.beta.gouv.fr" display="block" mb={2} mx="auto" width="50%" data-sentry-element="Link" data-sentry-source-file="NotFound.tsx">
              Page d&apos;accueil
            </Link>
          </Box>
        </GridItem>
      </Grid>
    </Flex>
    <Footer data-sentry-element="Footer" data-sentry-source-file="NotFound.tsx" />
  </Flex>;
export default NotFound;